import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	FaAngleDoubleRight,
	FaAngleRight,
	FaCheckCircle,
	FaExclamationCircle,
	FaPlusCircle,
	FaQuestionCircle
} from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Table } from "../../../utils/Table/TableWithFilter";
import { DiaperInterface } from "../../../interfaces/DiaperInterface";
import { DeltaProps } from "./DotationReducer";

interface Props {
	statistics: DeltaProps;
	diapers: DiaperInterface[];
	userLocale: string;
}

const DotationTable = ({ statistics, diapers, userLocale }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const data =
		statistics && statistics.seniors_results
			? statistics.seniors_results.map((value: any): void => {
					return {
						...value,
						seniorName: `${value.senior.last_name} ${value.senior.first_name}`,
						seniorId: value.senior.id,
						ok: value.counts.nb_ok,
						extra: value.counts.nb_extra,
						bad: value.counts.nb_found_but_bad,
						notFound: value.counts.nb_not_found,
						eventType: value.type,
						values: value.values.sort((a: any, b: any) => a.date.localeCompare(b.date))
					};
			  })
			: [];

	const displayDiaper = (diaperId: number): string | null => {
		const diaperSelected = diapers.find(diaper => diaper.id === diaperId);
		return diaperSelected ? diaperSelected.name : null;
	};

	const handleIcon = (type: string): JSX.Element | null => {
		switch (type) {
			case "OK":
				return <FaCheckCircle className="regular-icon green mr-2" />;
			case "NOT_FOUND":
				return <FaQuestionCircle className="regular-icon yellow mr-2" />;
			case "EXTRA":
				return <FaPlusCircle className="regular-icon blue mr-2" />;
			case "FOUND_BUT_BAD":
				return <FaExclamationCircle className="regular-icon red mr-2" />;
			default:
				return null;
		}
	};

	const displayDotationDetails = (value: any, provisions: any): JSX.Element => {
		return (
			<>
				{handleIcon(value.type)}
				<span className="meta mr-2">{handleDate(new Date(value.date), "P", userLocale)}</span>
				<FaAngleRight className="mr-2" />

				{value.type === "EXTRA" ? (
					<>
						{handleDate(new Date(value.change.done_at), "p", userLocale)}
						<span className="ml-3">
							<FaAngleDoubleRight className="mr-3" />
							{displayDiaper(value.change.diaper_id)}
						</span>
					</>
				) : (
					<>
						{provisions[value.index].begin_time}
						{value.type === "FOUND_BUT_BAD" ? (
							<>
								<span className="ml-3 mr-2">
									<FaAngleDoubleRight className="mr-3" />
									{displayDiaper(value.change.diaper_id)}
								</span>
								<p className="meta ml-4 red">
									<i>
										{t("diaper.__dotation__")} : {displayDiaper(provisions[value.index].diaper_id)}
									</i>
								</p>
							</>
						) : null}
					</>
				)}
			</>
		);
	};
	
	const columns = [
		{
			Header: t("common.__senior__"),
			accessor: "seniorName",
			disableFilters: true,
			minWidth: 80,
			width: 90,
			maxWidth: 100,
			Cell: (row: any) => (
				<Link to={`/senior/profile/${row.row.original.seniorId}`} className="category-outline-badge blue">
					{row.row.values.seniorName}
				</Link>
			)
		},
		{
			Header: t("diaper.__provisionMonitoring__"),
			accessor: "seniorId",
			disableFilters: true,
			Cell: (row: any) =>
				row.row.original.values.length > 0
					? row.row.original.values.map((value: any, idx: number): JSX.Element | null => (
							<div key={idx}>{displayDotationDetails(value, row.row.original.senior.provisions)}</div>
					  ))
					: null
		},
		{
			Header: <FaCheckCircle className="regular-icon green" />,
			accessor: "ok",
			disableFilters: true,
			minWidth: 20,
			width: 30,
			maxWidth: 35,
			Cell: (row: any) => (
				<p>
					<b>{row.row.values.ok}</b>
				</p>
			)
		},
		{
			Header: <FaExclamationCircle className="regular-icon red" />,
			accessor: "extra",
			disableFilters: true,
			minWidth: 20,
			width: 30,
			maxWidth: 35,
			Cell: (row: any) => (
				<p>
					<b>{row.row.values.bad}</b>
				</p>
			)
		},
		{
			Header: <FaPlusCircle className="regular-icon blue" />,
			accessor: "bad",
			disableFilters: true,
			minWidth: 20,
			width: 30,
			maxWidth: 35,
			Cell: (row: any) => (
				<p>
					<b>{row.row.values.extra}</b>
				</p>
			)
		},
		{
			Header: <FaQuestionCircle className="regular-icon yellow" />,
			accessor: "notFound",
			disableFilters: true,
			minWidth: 20,
			width: 30,
			maxWidth: 35,
			Cell: (row: any) => (
				<p>
					<b>{row.row.values.notFound}</b>
				</p>
			)
		}
	];

	return data.length > 0 ? (
		<Table columns={columns} data={data} />
	) : (
		<p>
			<FaAngleDoubleRight className="mr-2" />
			{t("common.__noElement__")}
		</p>
	);
};
export default DotationTable;
