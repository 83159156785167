import React, { useState } from "react";

interface Props {
	children: React.ReactNode;
	text: string;
}

export const Tooltip = ({ children, text }: Props): JSX.Element => {
	const [show, setShow] = useState(false);

	return (
		<span className="tooltip-container">
			<span className={show ? "tooltip-box visible" : "tooltip-box"}>
				{text}
			</span>
			<span onMouseEnter={(): void => setShow(true)} onMouseLeave={(): void => setShow(false)}>
				{children}
			</span>
		</span>
	);
};
