import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const MyInputEmail = props => {
	const { t } = useTranslation("common");
	const {
		register,
		formState: { errors }
	} = useFormContext();
	const [selectedValue, setSelectedValue] = useState(props.value);

	const onChange = e => {
		if (props.onChange !== undefined) {
			props.onChange(e);
		}
		setSelectedValue(e.target.value);
	};

	return (
		<div className="form-group mb-3">
			<label htmlFor={props.name} className="form-label">
				{props.label}
			</label>
			<input
				id={props.name}
				name={props.name}
				type="email"
				{...register(props.name, { required: props.behavior.required, pattern: props.behavior.pattern })}
				className="form-control form-control-sm"
				defaultValue={selectedValue || ""}
				onChange={e => onChange(e)}
				data-testid={props.dataTestid}
			/>

			{props.behavior.pattern ? (
				errors[props.name] ? (
					<p className="error-msg mt-2">{t("common.__incorrectMail__")}</p>
				) : null
			) : null}

			{props.behavior.required ? (
				<small className="form-text text-muted right" id={`${props.name}Error`}>
					{errors[props.name] ? (
						<span className="error">{t("common.__inputRequired__")}</span>
					) : (
						<span className="required">{t("common.__required__")}</span>
					)}
				</small>
			) : null}
		</div>
	);
};

export default MyInputEmail;
