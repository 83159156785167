import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { displayValidityTypeColor } from "./ValidityTypes";
import {
	FaRegEdit,
	FaAngleRight,
	FaRegTimesCircle,
	FaToggleOn,
	FaToggleOff,
	FaChevronRight,
	FaTimes
} from "react-icons/fa";
import HandleError from "../../../layout/HandleError";
import HandleSuccess from "../../../layout/HandleSuccess";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function HealthWorkersBadges({ store, dispatch }) {
	const { t } = useTranslation("common");
	const allBadges = store.users.badges;
	const [errorActivation, setErrorActivation] = useState(false);
	const [badges, setBadges] = useState(allBadges);

	useEffect(() => {
		if (allBadges.length > 0) {
			setBadges(allBadges);
		}
	}, [allBadges]);

	const filterBadges = filter => {
		switch (filter) {
			case "noRestriction":
				setBadges(allBadges.filter(badge => badge.validity.type === 0));
				break;
			case "restrictedDays":
				setBadges(allBadges.filter(badge => badge.validity.type === 3));
				break;
			case "restrictedTime":
				setBadges(allBadges.filter(badge => badge.validity.type === 2));
				break;
			case "occasionalBadge":
				setBadges(allBadges.filter(badge => badge.validity.type === 1));
				break;
			default:
				setBadges(allBadges);
		}
	};

	const removeBadge = id => {
		dispatch({
			type: "RESET_ES"
		});
		http
			.delete(`${url.BASEURL}/user_badges/${id}`)
			.then(() => {
				dispatch({
					type: "DELETE_BADGE",
					payload: parseInt(id)
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("badge.__removeSuccess__")
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const toggleBadge = id => {
		setErrorActivation(false);
		http
			.put(`${url.BASEURL}/user_badges/${id}/toggle`)
			.then(res => {
				dispatch({
					type: "TOGGLE_BADGE",
					payload: res.data.user_badge
				});
			})
			.catch(() => setErrorActivation(true));
	};

	const columns = useMemo(
		() => [
			{
				Header: t("common.__status__"),
				accessor: "state",
				Filter: SelectColumnFilter,
				maxWidth: 70,
				minWidth: 60,
				width: 65,
				Cell: row =>
					row.row.original.active ? (
						<span className="meta">
							<FaToggleOn className="ml-1 mr-1 fake-link blue" onClick={() => toggleBadge(row.row.values.id)} />
							{t("common.__active__")}
						</span>
					) : (
						<span className="meta">
							<FaToggleOff className="ml-1 mr-1 fake-link" onClick={() => toggleBadge(row.row.values.id)} />
							{t("common.__inactive__")}
						</span>
					)
			},
			{
				Header: t("badge.__badge__"),
				accessor: "value",
				Cell: row => {
					return (
						<Link to={`/user/hwbagde/${row.row.original.id}`}>
							<span className={` ${displayValidityTypeColor(row.row.original.validity.type)}`}>
								<b>{row.row.values.value}</b>
							</span>
						</Link>
					);
				}
			},
			{
				Header: t("common.__type__"),
				accessor: "type",
				Filter: SelectColumnFilter
			},
			{
				Header: t("common.__assignment__"),
				accessor: "affectation",
				Cell: row => (
					<>
						{row.row.original.user_id ? (
							<Link to={`/user/healthworker/${row.row.original.user_id}`}>
								<span className="category-outline-badge blue">
									<b>{row.row.values.affectation}</b>
								</span>
							</Link>
						) : (
							<span className="small-meta">
								<em>{t("common.__noAssignment__")}</em>
							</span>
						)}
					</>
				)
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				maxWidth: 85,
				minWidth: 75,
				width: 80,
				Cell: row => (
					<div className="center">
						<Link to={`/user/hwbagde/edit/${row.row.original.id}`}>
							<FaRegEdit className="table-icon" role="button" />
						</Link>
						<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeBadge(row.row.original.id)} />
						<Link to={`/user/hwbagde/${row.row.original.id}`}>
							<FaChevronRight className="table-icon" role="button" />
						</Link>
					</div>
				)
			}
		],
		[badges]
	);

	return (
		<>
			<HandleError error={store.es.error} dispatch={dispatch} message={store.es.msg} noSpace />
			<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
			{errorActivation && (
				<p className="error-msg">
					{t("common.__errorOccured__")}
					<span className="right" role="button" tabIndex="0" onClick={() => setErrorActivation(false)}>
						<FaTimes />
					</span>
				</p>
			)}
			{badges.length === 0 ? (
				<h5>
					<FaAngleRight className="mr-2" />
					{t("common.__noElement__")}
				</h5>
			) : (
				<>
					<div className="mt-4 mb-3 d-flex justify-content-between">
						<div>
							<span className="category-outline-badge green mr-2" role="button" onClick={() => filterBadges("noRestriction")}>
								{t("badge.__noRestriction__")}
							</span>
							<span
								className="category-outline-badge marine mr-2"
								role="button"
								onClick={() => filterBadges("restrictedTime")}>
								{t("badge.__restrictedTime__")}
							</span>
							<span className="category-outline-badge purple" role="button" onClick={() => filterBadges("occasionalBadge")}>
								{t("badge.__occasionalBadge__")}
							</span>
							<span className="category-outline-badge yellow" role="button" onClick={() => filterBadges("restrictedDays")}>
								{t("badge.__restrictedDays__")}
							</span>
							<span className="category-outline-badge grey" role="button" onClick={() => filterBadges("all")}>
								x
							</span>
						</div>
						<p className="meta mt-1">
							{badges.length} {t("homeBoard.__badges__")}
						</p>
					</div>
					<Table columns={columns} data={badges} />
				</>
			)}
		</>
	);
}
