import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaAngleRight, FaIdCardAlt, FaPhoneAlt, FaRegEnvelope, FaMobileAlt } from "react-icons/fa";
import { HealthWorkerInterface, StoreInterface, UserInterface } from "../../interfaces/UserInterface";
import HandleError from "../../layout/HandleError";
import HandleSuccess from "../../layout/HandleSuccess";
import Toggle from "../../utils/Toggle";
import * as p from "../../constants/Permissions";
import GoToPermissionIcon from "../Buttons/GoToPermissionIcon";
import TimelineIcon from "../Buttons/TimelineIcon";
import EditHealthWorkerButton from "../Buttons/EditHealthWorkerIcon";

interface Props {
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorkersListByHome = ({ store, dispatch, homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const canReadTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]);
	const isHomeManager = userState.userConnected.home_id;
	const [onlyActive, setOnlyActive] = useState(true);
	const [workersList, setWorkersList] = useState<HealthWorkerInterface[]>([]);
	const canManagePermissions = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.CAN_MANAGE_PERMISSIONS]);

	useEffect(() => {
		if (homeId) {
			if (isHomeManager) {
				setWorkersList(
					onlyActive ? store.users.healthWorkers.filter((hw: HealthWorkerInterface) => hw.active) : store.users.healthWorkers
				);
			} else {
				const workersFromThisHome = store.users.healthWorkers.filter((hw: HealthWorkerInterface) => hw.home_id === homeId);
				setWorkersList(
					onlyActive ? workersFromThisHome.filter((hw: HealthWorkerInterface) => hw.active) : workersFromThisHome
				);
			}
		}
	}, [homeId, isHomeManager, onlyActive]);

	const columns = [
		{
			Header: t("common.__name__"),
			accessor: "name",
			Cell: (row: any) => (
				<Link to={`/user/healthworker/${row.row.values.id}`}>
					<span className={`category-outline-badge ${row.row.original.active ? "blue" : "grey"}`}>
						<b>
							{row.row.original.last_name.toUpperCase()}{" "}
							{row.row.original.first_name === "badge_general" ? null : row.row.original.first_name}
						</b>
					</span>
				</Link>
			)
		},
		{
			Header: t("common.__job__"),
			accessor: "job",
			Filter: SelectColumnFilter
		},
		{
			Header: t("common.__contactDetails__"),
			accessor: "mobile_phone",
			Cell: (row: any) => (
				<>
					<a href={`mailto:${row.row.original.email}`}>
						<FaRegEnvelope className="mr-2" />
						{row.row.original.email}
					</a>
					{row.row.original.mobile_phone && (
						<p>
							<FaMobileAlt className="mr-2" />
							{row.row.original.mobile_phone}
						</p>
					)}
					{row.row.original.phone && (
						<p>
							<FaPhoneAlt className="mr-2" />
							{row.row.original.phone}
						</p>
					)}
				</>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			Cell: (row: any) => (
				<div className="d-inline-block float-right mr-2">
					{canReadTimeline && row.row.original.home_id ? <TimelineIcon type="HEALTHWORKER" id={row.row.values.id} /> : null}
					{row.row.original.badges ? (
						row.row.original.badges.length > 0 ? (
							<Link to={`/user/healthworker/${row.row.values.id}`}>
								<FaIdCardAlt className="table-icon" role="button" />
							</Link>
						) : (
							<Link to={`/user/healthworker/${row.row.values.id}`}>
								<FaIdCardAlt className="table-icon grey" role="button" />
							</Link>
						)
					) : null}
					<EditHealthWorkerButton userState={userState} userToEdit={row.row.original} />
					<GoToPermissionIcon canManagePermissions={canManagePermissions} user={row.row.original} />
				</div>
			)
		}
	];

	return (
		<>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__delError__")} />
			<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} noSpace />
			<div className="mb-3 d-flex justify-content-between">
				<Toggle
					isToggle={onlyActive}
					value={!onlyActive}
					action={(): void => setOnlyActive(prevState => !prevState)}
					textInactif={t("healthworker.__allUsers__")}
					textActif={t("healthworker.__onlyActiveUsers__")}
				/>
				<p className="meta">
					{workersList.length} {t("users.__users__")}
				</p>
			</div>

			{workersList.length > 0 ? (
				<Table columns={columns} data={workersList} />
			) : (
				<div className="mt-3">
					<h5>
						<FaAngleRight className="mr-2" />
						{t("common.__noElement__")}
					</h5>
				</div>
			)}
		</>
	);
};

export default HealthWorkersListByHome;
