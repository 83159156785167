import React from "react";
import { useTranslation } from "react-i18next";
import { AmdDiaperSettingsTabs, DiaperSettingsTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useDiaperStore from "../hooks/useDiaperStore";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import BluetoothActivation from "../components/DiaperConnected/Bluetooth/BluetoothActivation";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import AccessControl from "../layout/AccessControl";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number;
	userState: UserInterface;
}

const DiaperSettingsBluetooth = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { diaperStore } = useDiaperStore({ userState, homeId });
	const canUpdateHome = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]);
	const displayAmdSync = process.env.REACT_APP_AMD_SYNC_CONFIG === "true" ? true : false;

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={displayAmdSync ? AmdDiaperSettingsTabs : DiaperSettingsTabs} currentTab={"BLUETOOTH"} />
						<div className="big-card">
							{canUpdateHome ? (
								homeId ? (
									<BluetoothActivation homeId={homeId} diaperStore={diaperStore} />
								) : (
									<SelectHomeInput />
								)
							) : (
								<p className="red">
									<b>{t("common.__accessError__")}</b>
								</p>
							)}
						</div>
					</>
				}
			/>
		</AccessControl>
	);
};
export default DiaperSettingsBluetooth;
