import React from "react";
import { AmdDiaperSettingsTabs, DiaperSettingsTabs } from "../utils/Tabs/TabsToDisplay";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";
import IncoRecipientsContainer from "../components/DiaperConnected/NotificationsRecipients/IncoRecipientsContainer";
import { UserInterface } from "../interfaces/UserInterface";
import IncoRingtone from "../components/DiaperConnected/NotificationsRingtone/IncoRingtone";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	userDispatch: React.Dispatch<any>;
}

const DiaperSettingsRecipients = ({ userState, homeId, userDispatch }: Props): JSX.Element => {
	const isAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const displayAmdSync = process.env.REACT_APP_AMD_SYNC_CONFIG === "true" ? true : false;

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={displayAmdSync ? AmdDiaperSettingsTabs : DiaperSettingsTabs} currentTab={"RECIPIENTS"} />
						<br />
						<IncoRecipientsContainer userState={userState} homeId={homeId} isAdmin={isAdmin} />
						<br />
						<IncoRingtone userPrefs={userState.userConnected.uiprefs} userDispatch={userDispatch} />
					</>
				}
			/>
		</AccessControl>
	);
};
export default DiaperSettingsRecipients;
