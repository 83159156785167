import React from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleRight } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Table } from "../../../utils/Table/TableWithFilter";
import { InitialStateProps } from "../../../pages/DiaperChangeStatistics";
import { ChangeTableProps } from "../../../pages/DiaperChangeStatistics";
import { SeniorInterface } from "@e4ia/e4link_modules.seniorsidentificationmanager/dist/seniorsidentificationmanager.reducer";
import { FaCheckCircle } from "react-icons/fa";

interface Props {
	info: ChangeTableProps;
	changeStatisticStore: InitialStateProps;
	seniorsFromHome: SeniorInterface[];
	userLocale: string;
	filtereTableData: any | null;
}

const ChangeTableFiltered = ({ changeStatisticStore, seniorsFromHome, userLocale }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const info = changeStatisticStore?.tableData;

	function getIndicatorDescription(indicator: string, value: number): string | JSX.Element {
		const indicatorMap: { [key: string]: { [key: number]: string | JSX.Element } } = {
			diaper_saturated: {
				1: <>{t("diaper.__diaperSaturated__")} </>,
				2: (
					<>
						{t("diaper.__diaperSaturated__")} {`(${t("diaper.__half__")})`}
					</>
				)
			},
			draw_sheet_changed: {
				1: <>{t("diaper.__drawSheetChanged__")} </>
			},
			bring_to_wc: {
				1: <>{t("diaper.__bringToWc__")} </>
			},
			dirty_bed: {
				1: <>{t("diaper.__dirtyBed__")} </>
			},
			presence_of_redness: {
				1: <>{t("diaper.__presenceOfRedness__")} </>
			},
			torn_off: {
				1: <>{t("diaper.__diaperTornOff__")}</>
			},
			presence_of_stools: {
				1: <>{t("diaper.__presenceOfStools__")} </>
			}
		};

		return indicatorMap[indicator]?.[value];
	}
	const SelectedFilter = changeStatisticStore?.filter;

	const data = info
		? info
				.map((value: any) => {
					const senior = seniorsFromHome ? seniorsFromHome.find(s => s.id === value.senior_id) : null;

					return {
						...value,
						senior_id: value.senior_id,
						diaper_name: value.diaper_name,
						seniorName: senior ? senior.label : "no senior found",
						date: value.date ? handleDate(value.date, "Pp:ss", userLocale) : "no date found",
						dirty_bed: getIndicatorDescription("dirty_bed", value.dirty_bed),
						diaper_saturated: getIndicatorDescription("diaper_saturated", value.diaper_saturated),
						presence_of_stools: getIndicatorDescription("presence_of_stools", value.presence_of_stools),
						presence_of_redness: getIndicatorDescription("presence_of_redness", value.presence_of_redness),
						torn_off: getIndicatorDescription("torn_off", value.torn_off),
						draw_sheet_changed: getIndicatorDescription("draw_sheet_changed", value.draw_sheet_changed),
						bring_to_wc: getIndicatorDescription("bring_to_wc", value.bring_to_wc)
					};
				})
				.filter((value: any) => {
					if (
						(value.diaper_saturated && SelectedFilter.includes("diaper_saturated")) ||
						(value.draw_sheet_changed && SelectedFilter.includes("draw_sheet_changed")) ||
						(value.presence_of_stools && SelectedFilter.includes("presence_of_stools")) ||
						(value.presence_of_redness && SelectedFilter.includes("presence_of_redness")) ||
						(value.torn_off && SelectedFilter.includes("torn_off")) ||
						(value.dirty_bed && SelectedFilter.includes("dirty_bed")) ||
						(value.bring_to_wc && SelectedFilter.includes("bring_to_wc"))
					) {
						return true;
					}

					return false;
				})
		: [];
	const handleIcon = (type: string): { icon: JSX.Element } | null => {
		switch (type) {
			case "DIAPER_SATURATED":
				return {
					icon: <FaCheckCircle className="regular-icon blue mr-2" />
				};
			case "DRAW_SHEET_CHANGED":
				return {
					icon: <FaCheckCircle className="regular-icon darkred mr-2" />
				};
			case "BRING_TO_WC":
				return {
					icon: <FaCheckCircle className="regular-icon marine mr-2" />
				};
			case "DIRTY_BED":
				return {
					icon: <FaCheckCircle className="regular-icon red mr-2" />
				};
			case "PRESENCE_OF_REDNESS":
				return {
					icon: <FaCheckCircle className="regular-icon yellow mr-2" />
				};
			case "TORN_OFF":
				return {
					icon: <FaCheckCircle className="regular-icon purple mr-2" />
				};
			case "PRESENCE_OF_STOOLS":
				return {
					icon: <FaCheckCircle className="regular-icon green mr-2" />
				};
			default:
				return null;
		}
	};

	const columns = [
		{
			Header: t("common.__senior__"),
			accessor: "seniorName",
			disableFilters: true,
			Cell: (row: any) => (
				<p className="text-dark">
					<b>{row.row.values.seniorName ? row.row.values.seniorName : "not found"}</b>
				</p>
			)
		},
		{
			Header: t("common.__date__"),
			accessor: "date",
			disableFilters: true,
			Cell: (row: any) => (
				<p className="meta">
					<b>{row.row.values.date ? row.row.values.date : "not found"}</b>
				</p>
			)
		},

		{
			Header: t("diaper.__diaper__"),
			accessor: "diaper_name",
			disableFilters: true,

			Cell: (row: any) => (
				<b className="meta">
					{row.row.values.diaper_name ? (
						row.row.values.diaper_name
					) : (
						null
					)}
				</b>
			)
		},

		{
			Header: t("rules.__indicator__"),
			accessor: "indicator_name",
			disableFilters: true,

			Cell: (row: {
				row: {
					original: {
						diaper_saturated: string;

						draw_sheet_changed: string;

						bring_to_wc: string;

						dirty_bed: string;

						presence_of_redness: string;

						torn_off: string;

						presence_of_stools: string;
					};
				};
			}) => (
				<>
					{SelectedFilter.includes("diaper_saturated") && row.row.original.diaper_saturated && (
						<p className="meta">
							{handleIcon("DIAPER_SATURATED")?.icon}
							<b>{row.row.original.diaper_saturated}</b>
						</p>
					)}
					{SelectedFilter.includes("draw_sheet_changed") && row.row.original.draw_sheet_changed && (
						<p className="meta">
							{handleIcon("DRAW_SHEET_CHANGED")?.icon}
							<b>{row.row.original.draw_sheet_changed}</b>
						</p>
					)}
					{SelectedFilter.includes("bring_to_wc") && row.row.original.bring_to_wc && (
						<p className="meta">
							{handleIcon("BRING_TO_WC")?.icon}
							<b>{row.row.original.bring_to_wc}</b>
						</p>
					)}
					{SelectedFilter.includes("dirty_bed") && row.row.original.dirty_bed && (
						<p className="meta">
							{handleIcon("DIRTY_BED")?.icon}
							<b>{row.row.original.dirty_bed}</b>
						</p>
					)}
					{SelectedFilter.includes("presence_of_redness") && row.row.original.presence_of_redness && (
						<p className="meta">
							{handleIcon("PRESENCE_OF_REDNESS")?.icon}
							<b>{row.row.original.presence_of_redness}</b>
						</p>
					)}
					{SelectedFilter.includes("torn_off") && row.row.original.torn_off && (
						<p className="meta">
							{handleIcon("TORN_OFF")?.icon}
							<b>{row.row.original.torn_off}</b>
						</p>
					)}
					{SelectedFilter.includes("presence_of_stools") && row.row.original.presence_of_stools && (
						<p className="meta">
							{handleIcon("PRESENCE_OF_STOOLS")?.icon}
							<b>{row.row.original.presence_of_stools}</b>
						</p>
					)}
				</>
			)
		}
	];

	return data.length > 0 ? (
		<div>
			<Table columns={columns} data={data} />
		</div>
	) : (
		<div className="mt-3">
			<div className="big-card">
				<p className="red">{t("common.__noElement__")}</p>
				<p className="green">
					<b>
						<FaAngleDoubleRight /> {t("common.__selectIndicator__")}
					</b>
				</p>
			</div>
		</div>
	);
};

export default ChangeTableFiltered;
