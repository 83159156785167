import React, { useContext, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DiaperContext } from "../context/DiaperContext";
import { DiaperInterface } from "../interfaces/DiaperInterface";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import HomeDiapersList from "../components/DiaperConnected/Diapers/HomeDiapersList";
import DiapersList from "../components/DiaperConnected/Diapers/DiapersList";
import http from "../services/Interceptor";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DiapersReferenced = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const isAdmin = userState.userConnected.is(p.SUPER_ADMIN);
	const diapers = diaperStore.diapers;
	const homeDiapersListIds = diaperStore.homeDiapersList;
	const homeDiapersList = useMemo(
		() => homeDiapersListIds.map((id: number) => diapers.find((diaper: DiaperInterface) => diaper.id === id)),
		[diapers, homeDiapersListIds]
	);
	const [homeDiapersToDisplay, setHomeDiapersToDisplay] = useState([]);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (diapers.length === 0) {
			http
				.get(`${url.BASEURL}/rht/diapers`)
				.then(res => {
					diaperDispatch({
						type: "GET_DIAPERS",
						payload: res.data.diapers
					});
				})
				.catch(() => {
					diaperDispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__noData__")
					});
				});
		}
		// eslint-disable-next-line
	}, [diapers]);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/home_diapers_list`)
				.then(res => {
					setError(false);
					diaperDispatch({
						type: "GET_HOME_DIAPERS_LIST",
						payload: res.data.home_diapers_list
					});
				})
				.catch(() => setError(true));
		}
		// eslint-disable-next-line
	}, [homeId]);

	useEffect(() => {
		setHomeDiapersToDisplay(homeDiapersList);
	}, [homeDiapersList, homeId]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<HomeDiapersList
							homeDiapersList={homeDiapersList}
							error={error}
							setError={setError}
							homeDiapersToDisplay={homeDiapersToDisplay}
						/>
					) : isAdmin ? (
						<DiapersList diaperStore={diaperStore} diaperDispatch={diaperDispatch} />
					) : (
						<div className="big-card">
							<h2>{t("diaper.__referencedDiapers__")}</h2>
							<SelectHomeInput />
						</div>
					)
				}
			/>
		</AccessControl>
	);
};
export default DiapersReferenced;
