import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegEdit, FaRegTimesCircle } from "react-icons/fa";
import { displayRule, displayRuleCategory } from "./RuleUtils";
import DisplayName from "../../../utils/DisplayName";

export default function DisplayRule({ rules, canManageRules, store, removeRule, setShow }) {
	const { t } = useTranslation("common");
	const homeUnits = store.home.homeUnits;
	const seniorsFromHome = store.seniors.seniorsFromHome;

	const displaySeniorName = id => {
		const senior = seniorsFromHome.find(senior => senior.id === id);
		return senior ? senior.label : "";
	};

	return (
		<ul className="rules-list">
			{rules.map(rule => (
				<li key={rule.id} className="light-list list-group-item-action pt-4 pb-4">
					<div className="row">
						<div className="col-md-3 ">{displayRuleCategory(rule.category)}</div>
						<div className="col-md-7">
							{rule.home_unit_id ? <span className="meta">{DisplayName(rule.home_unit_id, homeUnits)}</span> : null}
							{rule.senior_id ? <span className="meta">{displaySeniorName(rule.senior_id)}</span> : null}
							<p>
								<b>{rule.name}</b>
							</p>
						</div>
						<div className="col-md-2 right">
							{canManageRules ? (
								<>
									<FaRegEdit
										className="table-icon"
										role="button"
										onClick={() => setShow({ show: true, info: { rule: rule }, title: t("rules.__editNotificationRules__") })}
									/>
									<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeRule(rule.id)} />
								</>
							) : null}
						</div>
						<div className="col-md-3"></div>
						<div className="col-md-9">{displayRule(rule.rule)}</div>
					</div>
				</li>
			))}
		</ul>
	);
}
