import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaBluetooth, FaRegEdit, FaRegTimesCircle, FaTimes, FaUnlink } from "react-icons/fa";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import HandleError from "../../../layout/HandleError";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import HeaderContainer from "../../../layout/HeaderContainer";
import ModalContainer, { ShowState } from "../../../utils/ModalContainer";
import EditSensor from "./EditSensor";
import AffectSeniorToSensor from "./AffectSeniorToSensor";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";
import RemoveSensorToSenior from "./RemoveSensorToSenior";
import { DiaperStoreInterface } from "../../../interfaces/DiaperInterface";
import { UserInterface } from "../../../interfaces/UserInterface";

interface Props {
	homeId: number;
	isAdmin: boolean;
	diaperStore: DiaperStoreInterface;
	diaperDispatch: React.Dispatch<any>;
	userState: UserInterface;
}

export default function DiaperSensorsList({
	homeId,
	isAdmin,
	diaperStore,
	diaperDispatch,
	userState
}: Props): JSX.Element {
	const { t } = useTranslation("common");
	const [showEditSensor, setShowEditSensor] = useState<ShowState>({
		show: false,
		info: { sensor: null },
		success: false
	});
	const [showAffectSenior, setShowAffectSenior] = useState<ShowState>({
		show: false,
		info: { sensor: null }
	});
	const [showRemoveSensor, setShowRemoveSensor] = useState<ShowState>({
		show: false,
		info: { senior: null },
		success: false
	});

	useEffect(() => {
		if (homeId && diaperStore.seniors.length > 0) {
			const seniorsWithSensor = diaperStore.seniors.filter(senior => senior.sensor_id);
			http.get(`${url.HOMESURL}/${homeId}/rht/diaper_sensors`).then(res => {
				const data = res.data.diaper_sensors.map((sensor: any) => {
					const resp = sensor;
					if(isAdmin === false) {resp.mac_address = resp.mac_address.split(":").slice(3).join("");}
					return resp;
				})
				diaperDispatch({
					type: "GET_SENSORS_WITH_SENIOR",
					payload: { sensors: data, seniors: seniorsWithSensor }
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, diaperStore.seniors]);

	const removeSensor = (id: number): void => {
		http
			.delete(`${url.BASEURL}/rht/diaper_sensor/${id}`)
			.then(() => {
				diaperDispatch({
					type: "DELETE_SENSOR",
					payload: id
				});
			})
			.catch(() => {
				diaperDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const columns = [
		{
			Header: t("common.__type__"),
			accessor: "type",
			Filter: SelectColumnFilter,
			minWidth: 25,
			width: 30,
			maxWidth: 35
		},
		{
			Header: "MAC",
			accessor: "mac_address",
			minWidth: 30,
			width: 35,
			maxWidth: 40,
			Cell: (row: any) => (
				<>
					<p>
						<b>{row.row.values.mac_address}</b>
					</p>
					{isAdmin ? (
						<>
							<p className="meta">
								Cal. Temp (°C) :{" "}
								{row.row.original.delta_t !== 0 ? (
									<strong>{row.row.original.delta_t}</strong>
								) : (
									<span>{row.row.original.delta_t}</span>
								)}
							</p>
							<p className="meta">
								Cal. HR (%) :{" "}
								{row.row.original.delta_h !== 0 ? (
									<strong>{row.row.original.delta_h}</strong>
								) : (
									<span>{row.row.original.delta_h}</span>
								)}
							</p>
						</>
					) : null}
				</>
			)
		},
		{
			Header: t("common.__senior__"),
			accessor: "senior.name",
			Filter: SelectColumnFilter,
			Cell: (row: any) => (
				<div>
					{row.row.original.senior ? (
						<Link to={`/senior/profile/${row.row.original.senior.id}`} className="category-outline-badge blue">
							<b>{row.row.original.senior.name}</b>
						</Link>
					) : (
						<p
							className="add-senior-badge"
							role="button"
							onClick={(): void =>
								setShowAffectSenior({ show: true, info: { sensor: row.row.original }, title: t("diaper.__affectSensor__") })
							}>
							<FaBluetooth className="table-icon" /> {t("senior.__assignSenior__")}
						</p>
					)}
				</div>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			maxWidth: 70,
			minWidth: 55,
			width: 65,
			Cell: (row: any) => (
				<div className="d-inline-block float-right mr-2">
					{row.row.original.senior ? (
						<FaUnlink
							className="table-icon red mr-2"
							role="button"
							onClick={(): void =>
								setShowRemoveSensor({
									show: true,
									info: { senior: row.row.original.senior },
									title: t("diaper.__sensor__"),
									success: false
								})
							}
						/>
					) : null}
					{isAdmin ? (
						<>
							<FaRegEdit
								className="table-icon"
								role="button"
								onClick={(): void => setShowEditSensor({ show: true, info: { sensor: row.row.original }, success: false })}
							/>
							<FaRegTimesCircle
								role="button"
								className="table-icon ml-2"
								onClick={(): void => removeSensor(row.row.values.id)}
							/>
						</>
					) : null}
				</div>
			)
		}
	];

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("diaper.__sensors__")}
				addButton={t("common.__add__")}
				link={"/inco/sensor/diapersensor/add"}
				permissionNeeded={[p.SUPER_ADMIN, p.ADMIN]}
				userState={userState}
			/>

			{homeId ? (
				<>
					<HandleError error={diaperStore.error} dispatch={diaperDispatch} message={diaperStore.msg} noSpace />
					{showEditSensor.success ? (
						<p className="success-msg mt-2">
							<span
								className="right"
								role="button"
								onClick={(): void => setShowEditSensor({ show: false, info: { sensor: null }, success: false })}>
								<FaTimes />
							</span>
							{t("common.__eltEditSuccess__")}
						</p>
					) : (
						<br />
					)}
					{diaperStore.sensors.length > 0 ? (
						<Table columns={columns} data={diaperStore.sensors} />
					) : (
						<p>{t("diaper.__noSensorAvailable__")}</p>
					)}
					<ModalContainer show={showEditSensor} setShow={setShowEditSensor}>
						<EditSensor diaperDispatch={diaperDispatch} show={showEditSensor} setShow={setShowEditSensor} />
					</ModalContainer>
					<ModalContainer show={showRemoveSensor} setShow={setShowRemoveSensor}>
						<RemoveSensorToSenior diaperDispatch={diaperDispatch} show={showRemoveSensor} setShow={setShowRemoveSensor} />
					</ModalContainer>
					<ModalContainer show={showAffectSenior} setShow={setShowAffectSenior}>
						<AffectSeniorToSensor
							show={showAffectSenior}
							setShow={setShowAffectSenior}
							seniors={diaperStore.seniors}
							diaperDispatch={diaperDispatch}
						/>
					</ModalContainer>
				</>
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
